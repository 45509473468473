export enum COMPONENT {
  ALL_PROCESSES = "allProcesses",
  ALL_TASKS = "allTasks",
  CREATE_PROCESS = "createProcess",
}

export enum STATUS {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  ABORTED = "ABORTED",
  SUSPENDED = "SUSPENDED",
}

export enum PROCESS_FILTER_CATEGORY {
  STATUS = "Status",
  SEARCH_KEY = "Business key",
}

export interface Filter {
  status: string[];
  searchKey?: string[];
}

export interface Comment {
  id: string;
  content: string;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  processId: string;
}

export interface ProcessListColumn {
  label: string;
  code: string;
  isSortable: boolean;
}

export interface ListMeta {
  columns: ProcessListColumn[];
  defaultSortIndex: number;
  processId: string;
  opportunityObject: string;
}

export interface ProcessToList {
  [key: string]: ListMeta;
}

export const PROCESS_TO_PROCESS_LIST_META: ProcessToList = {
  pcf: {
    columns: [
      {
        label: "Process Name - Business Key",
        code: "processName",
        isSortable: true,
      },
      {
        label: "PCF Type",
        code: "typeOfProjectChangeApproval",
        isSortable: false,
      },
      { label: "Created By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: true },
      { label: "Created at", code: "start", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: true },
    ],
    defaultSortIndex: 4,
    processId: "project_change_approval",
    opportunityObject: "pca",
  },
  evs: {
    columns: [
      {
        label: "Process Name - Business Key",
        code: "processName",
        isSortable: true,
      },
      { label: "Partner Name", code: "partnerName", isSortable: false },
      {
        label: "Partner Type",
        code: "partnerType",
        isSortable: false,
      },
      { label: "Status", code: "state", isSortable: true },
      { label: "Created at", code: "start", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: true },
    ],
    defaultSortIndex: 4,
    processId: "evfApproval",
    opportunityObject: "evs",
  },
  dinnerPlan: {
    columns: [
      {
        label: "Process Name - Business Key",
        code: "processName",
        isSortable: false,
      },
      { label: "Created By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: false },
      { label: "Created at", code: "start", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: false },
    ],
    defaultSortIndex: 3,
    processId: "dinnerPlan",
    opportunityObject: "dinner",
  },
  managedservices: {
    columns: [
      {
        label: "Process Name - Business Key",
        code: "processName",
        isSortable: false,
      },
      { label: "Created By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: false },
      { label: "Created at", code: "start", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: false },
    ],
    defaultSortIndex: 3,
    processId: "managedservices",
    opportunityObject: "managedServiceRequest",
  },
};

export const PROCESS_TO_TASK_LIST_META: ProcessToList = {
  pcf: {
    columns: [
      {
        label: "Task Name",
        code: "referenceName",
        isSortable: true,
      },
      {
        label: "Account Name",
        code: "accountName",
        isSortable: false,
      },
      {
        label: "PCF Type",
        code: "typeOfProjectChangeApproval",
        isSortable: true,
      },
      {
        label: "Opportunity Number",
        code: "opportunityNumber",
        isSortable: true,
      },
      { label: "Process Requested By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: true },
      { label: "Start", code: "started", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: true },
    ],
    defaultSortIndex: 6,
    processId: "project_change_approval",
    opportunityObject: "pca",
  },
  evs: {
    columns: [
      {
        label: "Task Name",
        code: "referenceName",
        isSortable: true,
      },
      {
        label: "Partner Name",
        code: "partnerName",
        isSortable: false,
      },
      {
        label: "Partner Type",
        code: "partnerType",
        isSortable: false,
      },
      {
        label: "Opportunity Number",
        code: "opportunityNumber",
        isSortable: true,
      },
      { label: "Process Requested By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: true },
      { label: "Start", code: "started", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: true },
    ],
    defaultSortIndex: 6,
    processId: "evfApproval",
    opportunityObject: "evs",
  },
  dinnerPlan: {
    columns: [
      {
        label: "Task Name",
        code: "referenceName",
        isSortable: true,
      },
      {
        label: "Place",
        code: "place",
        isSortable: false,
      },
      {
        label: "Pitch",
        code: "pitch",
        isSortable: false,
      },
      {
        label: "Budget",
        code: "budget",
        isSortable: false,
      },
      { label: "Process Requested By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: true },
      { label: "Start", code: "started", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: true },
    ],
    defaultSortIndex: 6,
    processId: "dinnerPlan",
    opportunityObject: "dinner",
  },
  managedservices: {
    columns: [
      {
        label: "Task Name",
        code: "referenceName",
        isSortable: true,
      },
      {
        label: "Proposed Product Name",
        code: "proposedProductName",
        isSortable: false,
      },
      { label: "Process Requested By", code: "createdBy", isSortable: false },
      { label: "Status", code: "state", isSortable: true },
      { label: "Start", code: "started", isSortable: true },
      { label: "Last update", code: "lastUpdate", isSortable: true },
    ],
    defaultSortIndex: 4,
    processId: "managedservices",
    opportunityObject: "managedServiceRequest",
  },
};

export const PCA_INPUTS = [
  {
    id: "opportunityNumber",
    label: "Opportunity Number",
  },
  {
    id: "projectId",
    label: "Project Id",
  },
  {
    id: "customerCompanyName",
    label: "Customer / Partner Name",
  },
  {
    id: "customerContactName",
    label: "Customer Contact Name",
  },
  {
    id: "customerEmailAddress",
    label: "Customer Email Address",
  },
  {
    id: "redHatRepresentativeName",
    label: "Red Hat Representative Name",
  },
  {
    id: "redHatRepresentativeEmail",
    label: "Red Hat Representative Email",
  },
  {
    id: "regionalServiceManagerEmail",
    label: "Regional Service Manager Email",
  },
  {
    id: "docuSignName",
    label: "DocuSign Name",
  },
  {
    id: "docuSignEmail",
    label: "DocuSign Email",
  },
];

export const PROCESS_DETAILS_FIELDS = [
  {
    id: "processName",
    label: "Name",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "businessKey",
    label: "Business key",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "id",
    label: "Id",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "createdBy",
    label: "Process Requester Name",
    type: "text",
    isNativeKey: false,
  },
  {
    id: "createdByEmail",
    label: "Process Requester Email",
    type: "text",
    isNativeKey: false,
  },
  {
    id: "state",
    label: "State",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "start",
    label: "Start",
    type: "date",
    isNativeKey: true,
  },
  {
    id: "lastUpdate",
    label: "Last Update",
    type: "date",
    isNativeKey: true,
  },
];

export const TASK_DETAILS_FIELDS = [
  {
    id: "referenceName",
    label: "Name",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "id",
    label: "Id",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "createdBy",
    label: "Process Requester Name",
    type: "text",
    isNativeKey: false,
  },
  {
    id: "createdByEmail",
    label: "Process Requester Email",
    type: "text",
    isNativeKey: false,
  },
  {
    id: "state",
    label: "State",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "processInstanceId",
    label: "Process Instance Id",
    type: "text",
    isNativeKey: true,
  },
  {
    id: "started",
    label: "Start",
    type: "date",
    isNativeKey: true,
  },
  {
    id: "lastUpdate",
    label: "Last Update",
    type: "date",
    isNativeKey: true,
  },
];

export const PCA_TYPE_TO_LABEL_AND_FIELDS = {
  EXT: {
    label: "Extension of dates",
    fields: [
      {
        label: "Original Start Date",
        key: "startDate",
        type: "date",
        fromOpportunity: true,
      },
      {
        label: "Original End Date",
        key: "endDate",
        type: "date",
        fromOpportunity: true,
      },
      { label: "New Start Date", key: "newStartDate", type: "date" },
      { label: "New End Date", key: "newEndDate", type: "date" },
      {
        label: "Why wasn't it possible to deliver within the contract term?",
        key: "contractTermResponse",
      },
      {
        label:
          "Please Include Link to PSA Scheduled Planned (not tentative) Assignments",
        key: "linkToPSAScheduledPlannedAssignments",
      },
      {
        label: "How many hours are remaining on the contract?",
        key: "numberOfExtensionsAlreadyHad",
      },
    ],
    type: "date",
  },
  ROH: {
    label: "Remix of Hours",
    fields: [
      { label: "Remix of Hours", key: "remixHours" },
      { key: "additionOfSKU", label: "Addition of SKU" },
    ],
    type: "text",
  },

  BTC: {
    label: "Billing Type Change",
    fields: [
      { label: "New Billing Type", key: "billType" },
      {
        label: "Justification",
        key: "justification",
        type: "textarea",
      },
    ],
    type: "text",
  },
  BTD: {
    label: "Backdating of term dates",
    fields: [
      { label: "New Start Date", key: "newStartDate", type: "date" },
      { label: "New End Date", key: "newEndDate", type: "date" },
    ],
    type: "date",
  },
  OTH: {
    label: "Other",
    fields: [
      { label: "Additional of scope", key: "additionalScope" },
      { label: "Change of Scope", key: "changeOfScope" },
    ],
    type: "text",
  },
};

export interface Fields {
  id: string;
  label: string;
  type: string;
}

export interface ProcessDetail {
  opportunityFields: Fields[];
  extendedTasks: string[];
  processId: string;
}

export interface ProcessToDetail {
  [key: string]: ProcessDetail;
}

export const PROCESS_TO_DETAIL_META: ProcessToDetail = {
  pcf: {
    opportunityFields: [
      {
        id: "superRegion",
        label: "Super Region",
        type: "text",
      },
      {
        id: "opportunityNumber",
        label: "Opportunity Number",
        type: "text",
      },
      {
        id: "opportunityOwner",
        label: "Opportunity Owner",
        type: "text",
      },
      {
        id: "accountName",
        label: "Account Name",
        type: "text",
      },
      {
        id: "regionalServiceManagerName",
        label: "Regional Service Manager Name",
        type: "text",
      },
      {
        id: "regionalServiceManagerEmail",
        label: "Regional Service Manager Email",
        type: "text",
      },
      {
        id: "opaProjectNumber",
        label: "OPA Project Number",
        type: "text",
      },
      {
        id: "projectManager",
        label: "Project Manager",
        type: "text",
      },
      {
        id: "projectManagerEmail",
        label: "Project Manager Email",
        type: "text",
      },
      {
        id: "opportunityStage",
        label: "Opportunity Stage",
        type: "text",
      },
      {
        id: "ownerRole",
        label: "Owner Role",
        type: "text",
      },
      {
        id: "projectId",
        label: "Project Id",
        type: "text",
      },
      {
        id: "projectType",
        label: "Project Type",
        type: "text",
      },
      {
        id: "billingType",
        label: "Billing Type",
        type: "text",
      },
      {
        id: "billingArrangement",
        label: "Billing Arrangement",
        type: "text",
      },
      {
        id: "currency",
        label: "Currency",
        type: "text",
      },
      {
        id: "totalRemainingRevenueBacklog",
        label: "Total Remaining Revenue Backlog",
        type: "text",
      },
      {
        id: "budgetStatus",
        label: "Budget Status",
        type: "text",
      },
      {
        id: "budgetIdentifier",
        label: "Budget Identifier",
        type: "text",
      },
      {
        id: "budgetName",
        label: "Budget Name",
        type: "text",
      },
      {
        id: "startDate",
        label: "Start Date",
        type: "arrayDate",
      },
      {
        id: "endDate",
        label: "End Date",
        type: "arrayDate",
      },
      {
        id: "closeDate",
        label: "Close Date",
        type: "arrayDate",
      },
      {
        id: "totalPlannedHours",
        label: "Total Planned Hours",
        type: "text",
      },
      {
        id: "totalRemainingHours",
        label: "Total Remaining Hours",
        type: "text",
      },
      {
        id: "totalRemainingRevenueBacklog",
        label: "Total Remaining Revenue Backlog",
        type: "text",
      },
    ],
    extendedTasks: [],
    processId: "project_change_approval",
  },
  evs: {
    opportunityFields: [
      {
        id: "opportunityNumber",
        label: "Opportunity Number",
        type: "text",
      },
      {
        id: "superRegion",
        label: "Super Region",
        type: "text",
      },
      {
        id: "region",
        label: "Region",
        type: "text",
      },
      {
        id: "subRegion",
        label: "Sub Region",
        type: "text",
      },
      {
        id: "actualSybUsed",
        label: "Actual Syb Used(in USD)",
        type: "currency",
      },
      {
        id: "globalCustomerName",
        label: "Global Customer  Name",
        type: "text",
      },
      {
        id: "fiscalYearQuarter",
        label: "Fiscal Year Quarter",
        type: "text",
      },
      {
        id: "resellerPartnerName",
        label: "Reseller Partner Name",
        type: "text",
      },
      {
        id: "resellerPartnerType",
        label: "Reseller Partner Type",
        type: "text",
      },
      {
        id: "financialPartnerName",
        label: "Financial Partner Name",
        type: "text",
      },
      {
        id: "financialPartnerType",
        label: "Financial Partner Type",
        type: "text",
      },
      {
        id: "influencingPartnerList",
        label: "Influencing Partner List",
        type: "text",
      },
      {
        id: "forecastProductGroupList",
        label: "Forecast Product Group(s)",
        type: "text",
      },
      {
        id: "closeDate",
        label: "Close Date",
        type: "arrayDate",
      },
    ],
    extendedTasks: [],
    processId: "evfApproval",
  },
  dinnerPlan: {
    opportunityFields: [
      {
        id: "title",
        label: "Title",
        type: "text",
      },
      {
        id: "recipeId",
        label: "Recipe Id",
        type: "text",
      },
      {
        id: "publisher",
        label: "Publisher",
        type: "text",
      },
      {
        id: "publisherUrl",
        label: "Publisher Url",
        type: "url",
      },
      {
        id: "socialRank",
        label: "Social Rank",
        type: "text",
      },
      {
        id: "sourceUrl",
        label: "Source Url",
        type: "url",
      },
      {
        id: "imageUrl",
        label: "Image",
        type: "image",
      },
    ],
    extendedTasks: [],
    processId: "dinnerPlan",
  },
  managedservices: {
    opportunityFields: [],
    extendedTasks: [
      "meeting",
      "productnamecheck",
      "populatesbr",
      "isCpccmAndSbrComplete",
      "isKCSAndSFDCRequired",
      "isTrainingComplete",
      "approvedoc",
      "pushvariabletodoc",
    ],
    processId: "managedservices",
  },
};

export const EVS_INPUTS_FIELDS = [
  {
    id: "opportunityNumber",
    label: "Opportunity Number",
  },
  {
    id: "partnerName",
    label: "Partner Name",
  },
  {
    id: "partnerType",
    label: "Partner Type",
  },
  {
    id: "typeOfOpportunity",
    label: "Type Of Opportunity",
  },
  {
    id: "cosellCriteria",
    label: "Sell-with Criteria",
  },
  {
    id: "cosellDescription",
    label: "Sell-with Description",
    type: "textarea",
  },
  {
    id: "notifyEmails",
    label: "Watchers",
    type: "arrayString",
  },
];

export const DINNER_PLAN_INPUTS_FIELDS = [
  {
    id: "place",
    label: "Place",
    type: "text",
  },
  {
    id: "budget",
    label: "Budget",
    type: "text",
  },
  {
    id: "pitch",
    label: "Pitch",
    type: "text",
  },
  {
    id: "managerEmail",
    label: "Manager Email",
    type: "text",
  },
  {
    id: "scrumMasterEmail",
    label: "Scrum Master Email",
    type: "text",
  },
  {
    id: "date",
    label: "Date",
    type: "timestamp",
  },
];

export const MANAGED_SERVICES_INPUTS_FIELDS = [
  {
    id: "proposedProductName",
    label: "Proposed Product Name",
    type: "text",
  },
  {
    id: "shortDescription",
    label: "Short Description",
    type: "text",
  },
  {
    id: "productTeam",
    label: "Product Team",
    type: "text",
  },
  {
    id: "relatedExistingOfferings",
    label: "Related Existing Offerings",
    type: "text",
  },
  {
    id: "sku",
    label: "SKU",
    type: "text",
  },
  {
    id: "pxeContact",
    label: "PXE/PgM Contact",
    type: "text",
  },
  {
    id: "engineeringContact",
    label: "Engineering Contact",
    type: "text",
  },
  {
    id: "sreContact",
    label: "SRE Contact",
    type: "text",
  },
  {
    id: "techPreviewDate",
    label: "Tech/Service Preview Date",
    type: "timestamp",
  },
  {
    id: "limitedAvailabilityDate",
    label: "Limited Availability Date",
    type: "timestamp",
  },
  {
    id: "generalAvailabilityDate",
    label: "General Availability Date",
    type: "timestamp",
  },
];

export const PCF_OUTPUT_FIELDS = [
  {
    id: "driveDocId",
    label: "Generated Document",
    type: "googleDocId",
  },
];

export const DINNER_OUTPUT_FIELDS = [
  {
    id: "templateDocId",
    label: "Template Document",
    type: "googleDocId",
  },
  {
    id: "driveDocId",
    label: "Generated Document",
    type: "googleDocId",
  },
];

export const MANAGED_SERVICES_OUTPUT_FIELDS = [
  {
    id: "productName",
    label: "Product Name",
    type: "text",
  },
  {
    id: "driveFolderId",
    label: "Google drive folder",
    type: "googleDriveFolderId",
  },
  {
    id: "componentId",
    label: "Component Id",
    type: "text",
  },
  {
    id: "meetingLink",
    label: "Meeting Link",
    type: "url",
  },
  {
    id: "epicURL",
    label: "Epic URL",
    type: "url",
  },
  {
    id: "cpccmIssueURL",
    label: "Enable Product in Customer Portal Jira Ticket",
    type: "url",
  },
  {
    id: "sbrTeam",
    label: "SBR Team",
    type: "text",
  },
  {
    id: "sbrIssueURL",
    label: "Create New SBR Jira Ticket",
    type: "url",
  },
  {
    id: "routingIssueURL",
    label: "Product Routing Jira Ticket",
    type: "url",
  },
  {
    id: "kcsIssueURL",
    label: "Add tags in KCS Jira Ticket",
    type: "url",
  },
  {
    id: "sfdcIssueURL",
    label: "Add tags in SFDC Jira Ticket",
    type: "url",
  },
];
