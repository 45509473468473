import {
  FlexItem,
  CardHeader,
  Title,
  CardBody,
  Form,
  FormGroup,
  TextArea,
  TextInput,
  Text,
  TextVariants,
  HelperText,
  HelperTextItem,
  LabelGroup,
  Label,
} from "@patternfly/react-core";
import "./tabContents.scss";
import {
  AGREEMENT_OPTIONS,
  PRODUCT_TYPES,
  PRODUCT_TYPE_OPTIONS,
} from "../../../constants/CreateProcess";
import {
  PCA_TYPE_TO_LABEL_AND_FIELDS,
  PCA_INPUTS,
  Fields,
  EVS_INPUTS_FIELDS,
  DINNER_PLAN_INPUTS_FIELDS,
  MANAGED_SERVICES_INPUTS_FIELDS,
  MANAGED_SERVICES_OUTPUT_FIELDS,
  DINNER_OUTPUT_FIELDS,
  PCF_OUTPUT_FIELDS,
} from "../../../constants/Process";
import { getValueByFieldType } from "../../../utils";
import { OFFERING_TYPES } from "../../../constants/ManagedServices";

const renderPCAInputs = (pcaInputs: any): JSX.Element => {
  const pcaType =
    PCA_TYPE_TO_LABEL_AND_FIELDS[pcaInputs?.typeOfProjectChangeApproval];
  const typeOfAgreement =
    AGREEMENT_OPTIONS.find((option) => {
      return option.value === pcaInputs?.typeOfAgreement;
    })?.label || "N/A";
  const typeOfProduct =
    PRODUCT_TYPE_OPTIONS.find((option) => {
      return option.value === pcaInputs?.typeOfProduct;
    })?.label || "N/A";

  return (
    <FlexItem style={{ minHeight: "600px" }}>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Inputs
        </Title>
      </CardHeader>
      <CardBody>
        <Form>
          {pcaInputs &&
            PCA_INPUTS.map((field) => (
              <FormGroup label={field.label} fieldId={field.id}>
                <TextInput
                  readOnly
                  value={pcaInputs?.[field.id]}
                  id={field.id}
                />
              </FormGroup>
            ))}
          <FormGroup
            label="Type Of Project Change Approval"
            fieldId="typeOfProjectChangeApproval"
          >
            <TextInput
              readOnly
              value={pcaType?.label || "N/A"}
              id="typeOfProjectChangeApproval"
            />
          </FormGroup>
          <FormGroup label="Type Of Agreement" fieldId="typeOfAgreement">
            <TextInput readOnly value={typeOfAgreement} id="typeOfAgreement" />
          </FormGroup>
          <FormGroup label="Type Of Product" fieldId="typeOfProduct">
            <TextInput readOnly value={typeOfProduct} id="typeOfProduct" />
          </FormGroup>
          {pcaType?.fields.map((field) => (
            <FormGroup label={field.label} fieldId={field.key}>
              {field.type && field.type === "textarea" ? (
                <TextArea
                  autoResize
                  readOnly
                  value={pcaInputs?.[field.key]}
                  id={field.key}
                />
              ) : (
                <TextInput
                  readOnly
                  value={
                    field?.type === "date"
                      ? field?.fromOpportunity
                        ? pcaInputs?.opportunity?.[field.key].join("-")
                        : pcaInputs[field.key].join("-")
                      : pcaInputs?.[field.key]
                  }
                  id={field.key}
                />
              )}
              <HelperText>
                <HelperTextItem>
                  {field?.type === "date" ? "YYYY-MM-DD" : ""}
                </HelperTextItem>
              </HelperText>
            </FormGroup>
          ))}
          {(typeOfProduct === PRODUCT_TYPES.CONSULTING_UNIT ||
            typeOfProduct === PRODUCT_TYPES.FIXED_PRICE) && (
            <FormGroup
              label="Total Remaining Hours/Amount"
              fieldId="totalRemainingHours"
            >
              <TextInput
                readOnly
                value={pcaInputs?.totalRemainingHours}
                id="totalRemainingHours"
              />
            </FormGroup>
          )}
        </Form>
      </CardBody>
    </FlexItem>
  );
};

const renderEVFInputs = (evfInputs: any): JSX.Element => {
  return (
    <FlexItem style={{ minHeight: "600px" }}>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Inputs
        </Title>
      </CardHeader>
      <CardBody>
        <Form>
          {evfInputs &&
            EVS_INPUTS_FIELDS.map((field) => (
              <FormGroup label={field.label} fieldId={field.id}>
                {field.type && field.type === "textarea" ? (
                  <TextArea
                    autoResize
                    readOnly
                    value={evfInputs?.[field.id]}
                    id={field.id}
                  />
                ) : (
                  <TextInput
                    readOnly
                    value={getValueByFieldType(
                      field.type,
                      evfInputs?.[field.id]
                    )}
                    id={field.id}
                  />
                )}
              </FormGroup>
            ))}
        </Form>
      </CardBody>
    </FlexItem>
  );
};

const renderManagedServicesInputs = (inputs: any): JSX.Element => {
  const typeOfOffering = OFFERING_TYPES.find(
    (type) => type.value === inputs?.typeOfOffering
  );
  const { links } = inputs;

  return (
    <FlexItem style={{ minHeight: "600px" }}>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Inputs
        </Title>
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup label="Type of Offering" fieldId="typeOfOffering">
            <TextInput
              readOnly
              value={typeOfOffering.label}
              id="typeOfOffering"
            />
          </FormGroup>
          {inputs &&
            MANAGED_SERVICES_INPUTS_FIELDS.map((field) => (
              <FormGroup label={field.label} fieldId={field.id} key={field.id}>
                {field.type && field.type === "textarea" ? (
                  <TextArea
                    autoResize
                    readOnly
                    value={inputs?.[field.id]}
                    id={field.id}
                  />
                ) : (
                  <TextInput
                    readOnly
                    value={getValueByFieldType(field.type, inputs?.[field.id])}
                    id={field.id}
                  />
                )}
              </FormGroup>
            ))}

          {links?.length ? (
            <FormGroup label="Links" id="links">
              {links.map(({ label, link }) => (
                <Text>
                  <a href={link} target="_blank" style={{ fontSize: 16 }}>
                    {label}
                  </a>
                </Text>
              ))}
            </FormGroup>
          ) : (
            ""
          )}
        </Form>
      </CardBody>
    </FlexItem>
  );
};

const renderDinnerPlanInputs = (demoInputs: any): JSX.Element => {
  return (
    <FlexItem style={{ minHeight: "600px" }}>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Inputs
        </Title>
      </CardHeader>
      <CardBody>
        <Form>
          {demoInputs &&
            DINNER_PLAN_INPUTS_FIELDS.map((field) => (
              <FormGroup label={field.label} fieldId={field.id} key={field.id}>
                {field.type && field.type === "textarea" ? (
                  <TextArea
                    autoResize
                    readOnly
                    value={demoInputs?.[field.id]}
                    id={field.id}
                  />
                ) : (
                  <TextInput
                    readOnly
                    value={getValueByFieldType(
                      field.type,
                      demoInputs?.[field.id]
                    )}
                    id={field.id}
                  />
                )}
              </FormGroup>
            ))}
        </Form>
      </CardBody>
    </FlexItem>
  );
};

const renderOpportunityDetails = (
  opportunityFields: Fields[],
  opportunityDetails: any
): JSX.Element => {
  return (
    <FlexItem style={{ minHeight: "600px" }}>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Enriched Data
        </Title>
      </CardHeader>
      <CardBody>
        <Form>
          {opportunityDetails &&
            opportunityFields.map((field) => (
              <FormGroup label={field.label} fieldId={field.id} key={field.id}>
                <Text component={TextVariants.p}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getValueByFieldType(
                        field.type,
                        opportunityDetails?.[field.id]
                      ),
                    }}
                  ></span>
                </Text>
              </FormGroup>
            ))}
        </Form>
      </CardBody>
    </FlexItem>
  );
};

const renderProcessInputs = (inputs: any, processCode: string): JSX.Element => {
  switch (processCode) {
    case "pcf":
      return renderPCAInputs(inputs);
    case "evs":
      return renderEVFInputs(inputs);
    case "dinnerPlan":
      return renderDinnerPlanInputs(inputs);
    case "managedservices":
      return renderManagedServicesInputs(inputs);
  }
};

const getOutputFieldsByProcessCode = (processCode: string) => {
  switch (processCode) {
    case "pcf":
      return PCF_OUTPUT_FIELDS;
    case "evs":
      return [];
    case "dinnerPlan":
      return DINNER_OUTPUT_FIELDS;
    case "managedservices":
      return MANAGED_SERVICES_OUTPUT_FIELDS;
  }
};

const renderOutputDetails = (outputValues: any, processCode: string) => {
  const outputFields = getOutputFieldsByProcessCode(processCode);

  return (
    <FlexItem style={{ minHeight: "600px" }}>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Outputs
        </Title>
      </CardHeader>
      <CardBody>
        <Form>
          {outputFields.map(
            ({ id, label, type }) =>
              outputValues[id] && (
                <FormGroup label={label} fieldId={id} key={id}>
                  <Text>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getValueByFieldType(type, outputValues[id]),
                      }}
                    />
                  </Text>
                </FormGroup>
              )
          )}
        </Form>
      </CardBody>
    </FlexItem>
  );
};

export {
  renderPCAInputs,
  renderEVFInputs,
  renderOpportunityDetails,
  renderProcessInputs,
  renderOutputDetails,
};
