export enum STATUS {
  READY = "Ready",
  COMPLETED = "Completed",
  RESERVED = "Reserved",
  ABORT = "Abort",
  SKIP = "Skip",
}

export enum TASK_FILTER_CATEGORY {
  STATUS = "Status",
  SEARCH_KEY = "Task Name",
}

export interface Filter {
  status: string[];
  searchKey?: string[];
}

export const TASK_CONTROL_OBJECT = {
  meeting: {
    values: {
      reason: "",
    },
    fields: [
      {
        id: "reason",
        label: "Explain your action.",
        type: "textarea",
        isRequired: true,
      },
    ],
  },
  productnamecheck: {
    values: {
      productName: "",
      cpccmRequired: false,
      mailingList: "",
      productVersion: "",
      versionShowLinkToKraken: "",
    },
    fields: [
      {
        id: "cpccmRequired",
        label: "Open Jira for Enabling Product in Customer Portal",
        type: "checkbox",
        isRequired: false,
        disableFormLabel: true,
        description: "Opt it to enable the fields given below.",
      },
      {
        id: "productName",
        label: "Product Name",
        type: "text",
        isRequired: true,
        disabled: "cpccmRequired",
      },
      {
        id: "mailingList",
        label:
          "Is there a Watch-list/Mailing List for the Product (so when a Sev 1 is filed against it the mailing list is alerted?)",
        type: "text",
        isRequired: true,
        disabled: "cpccmRequired",
      },
      {
        id: "productVersion",
        label: "The Versions for the Product",
        type: "text",
        isRequired: true,
        disabled: "cpccmRequired",
      },
      {
        id: "versionShowLinkToKraken",
        label:
          "Should the Version show a link to Kraken (an OpenShift diagnostic tool)",
        type: "text",
        isRequired: true,
        disabled: "cpccmRequired",
      },
    ],
  },
  populatesbr: {
    values: {
      sbrTeam: "",
    },
    fields: [
      {
        id: "sbrTeam",
        label: "SBR Team Name",
        type: "selectWithCreate",
        isRequired: true,
        isNewSBR: false,
      },
    ],
  },
  approvedoc: {
    values: {
      docApproved: false,
    },
    fields: [
      {
        id: "docApproved",
        label:
          "Do the stakeholders approve of the final version of the General Availability Support Plan Document?",
        type: "checkbox",
        isRequired: true,
        disableFormLabel: true,
        description:
          "Do not proceed unless the Engineering, PLM, and Support teams have approved the document.",
      },
    ],
  },
  isCpccmAndSbrComplete: {
    values: {},
    fields: [
      {
        id: "jiraCompleted",
        type: "acknowledgement",
        label:
          "Please approve this task only if the following Jira ticket(s) have been completed.",
        options: [
          {
            id: "cpccmRequired",
            label: "Enable Product in Customer Portal",
          },
          {
            id: "isNewSBR",
            label: "Create New SBR",
          },
        ],
        disableFormLabel: true,
      },
    ],
  },
  isKCSAndSFDCRequired: {
    values: {
      kcsTagsRequired: false,
      kcsVariables: [],
      sfdcTagsRequired: false,
      sfdcVariables: [],
    },
    fields: [
      {
        id: "kcsTagsRequired",
        label: "Add tag(s) to KCS",
        type: "checkbox",
        disableFormLabel: true,
      },
      {
        id: "kcsVariables",
        type: "multiTags",
        disabled: "kcsTagsRequired",
      },
      {
        id: "sfdcTagsRequired",
        label: "Add tag(s) to SFDC",
        type: "checkbox",
        disableFormLabel: true,
      },
      {
        id: "sfdcVariables",
        type: "multiTags",
        disabled: "sfdcTagsRequired",
      },
    ],
  },
  pushvariabletodoc: {
    values: {
      pushVariablesToDoc: false,
    },
    fields: [
      {
        id: "pushVariablesToDoc",
        label: "Push variables to General Availability Support Plan Document",
        type: "checkbox",
        disableFormLabel: true,
      },
    ],
  },
  isTrainingComplete: {
    values: {
      isTrainingComplete: false,
    },
    fields: [
      {
        id: "isTrainingComplete",
        label: "Is training complete?",
        description:
          "Ensure that training materials are created, sessions are conducted, and resource links are posted on the SBR pages before approving this task.",
        type: "checkbox",
        disableFormLabel: true,
      },
    ],
  },
};

export const TYPE_OF_APPROVALS = [
  {
    label: "PA Approval",
    value: "paApprovalRequired",
  },
  {
    label: "Revenue Approval",
    value: "revenueApprovalRequired",
  },
  {
    label: "Service Approval",
    value: "serviceApprovalRequired",
  },
];
