import { useEffect, useState } from "react";
import BasicTextInput from "../../../components/InputFields/BasicTextInput";
import {
  FlexItem,
  CardHeader,
  Title,
  Form,
  ActionGroup,
  Button,
  FormGroup,
} from "@patternfly/react-core";
import { DateInput } from "../../../components/InputFields";
import moment from "moment";
import _ from "lodash";
import fields from "../../../constants/Variable";

const Variables = ({
  inputs,
  outputs,
  onSaveVariables,
  taskName,
}): JSX.Element => {
  const [formValues, setFormValues] = useState(
    _.has(outputs, "variables")
      ? outputs.variables
      : inputs.managedServiceRequest.variables
  );

  const onSave = () => {
    const payload = {
      variables: { ...formValues },
    };
    onSaveVariables(payload, true);
  };

  useEffect(() => {
    if (!_.has(outputs, "variables") || outputs.variables === null) {
      onSaveVariables(
        { variables: inputs.managedServiceRequest.variables },
        false
      );
    }
  }, []);

  return (
    <FlexItem>
      <CardHeader>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title headingLevel="h3" size="xl">
            Variables
          </Title>
          <ActionGroup>
            <Button
              onClick={onSave}
              style={{ fontWeight: "500" }}
              variant="primary"
            >
              Save
            </Button>
          </ActionGroup>
        </div>
      </CardHeader>
      {formValues && (
        <Form>
          {fields.map(({ label, key, type, isRequired }) => {
            if (type === "date") {
              return (
                <FormGroup
                  id={key}
                  label={label}
                  isRequired={taskName === "pushvariabletodoc" && isRequired}
                >
                  <DateInput
                    value={formValues[key]}
                    id={key}
                    setValue={(value) => {
                      setFormValues({
                        ...formValues,
                        [key]: moment(value).toISOString(),
                      });
                    }}
                  />
                </FormGroup>
              );
            } else {
              return (
                <BasicTextInput
                  style={{ width: "70%" }}
                  isRequired={taskName === "pushvariabletodoc" && isRequired}
                  maxLength={180}
                  id={key}
                  label={label}
                  value={formValues[key]}
                  setValue={(value) => {
                    setFormValues({ ...formValues, [key]: value });
                  }}
                />
              );
            }
          })}
        </Form>
      )}
    </FlexItem>
  );
};
export default Variables;
