import {
  FlexItem,
  PageSection,
  PageSectionVariants,
  Form,
  TextArea,
  TextInput,
  FormGroup,
  Checkbox,
  Button,
  Title,
  Label,
  LabelGroup,
} from "@patternfly/react-core";
import React, { useEffect, useState } from "react";
import SelectTypeaheadCreatable from "../../../components/InputFields/SelectTypeaheadCreatable";
import { getSbrTeams } from "./apis";
import { utilsApiEndpoints } from "../../../apis/endpoints";

const ExtendedTask = (props: any): JSX.Element => {
  const [sbrTeams, setSbrTeams] = useState([]);
  const {
    taskControlFields,
    taskControlValues,
    setTaskControlValues,
    taskDataInputs,
  } = props;
  const [kcsVariable, setKcsVariable] = useState("");
  const [kcsVariableList, setKcsVariableList] = useState([]);
  const [sfdcVariable, setSfdcVariable] = useState("");
  const [sfdcVariableList, setSfdcVariableList] = useState([]);

  useEffect(() => {
    if (taskControlFields.some((field) => field.type === "selectWithCreate"))
      fetchSbrTeams();
  }, [taskControlFields]);

  const fetchSbrTeams = async () => {
    await getSbrTeams({
      url: utilsApiEndpoints.sbrTeams,
      successCallback: (response) => {
        const sbrTeamsList = response.items.map((item) => {
          return {
            value: item?.name,
            children: item?.name,
          };
        });
        setSbrTeams(sbrTeamsList);
      },
    });
  };

  const onClickAddMore = (id) => {
    if (id === "kcsVariables" && kcsVariable.trim() !== "") {
      const updatedList = [...kcsVariableList, kcsVariable.trim()];
      setKcsVariableList(updatedList);
      setTaskControlValues({
        ...taskControlValues,
        [id]: updatedList,
      });
      setKcsVariable("");
    } else if (id === "sfdcVariables" && sfdcVariable.trim() !== "") {
      const updatedList = [...sfdcVariableList, sfdcVariable.trim()];
      setSfdcVariableList(updatedList);
      setTaskControlValues({
        ...taskControlValues,
        [id]: updatedList,
      });
      setSfdcVariable("");
    }
  };

  const onRemoveListItem = (indexToRemove, id) => {
    if (id === "kcsVariables") {
      const updatedList = kcsVariableList.filter(
        (_, index) => index !== indexToRemove
      );
      setKcsVariableList(updatedList);
      setTaskControlValues((prev) => ({
        ...prev,
        [id]: updatedList,
      }));
    } else if (id === "sfdcVariables") {
      const updatedList = sfdcVariableList.filter(
        (_, index) => index !== indexToRemove
      );
      setSfdcVariableList(updatedList);
      setTaskControlValues((prev) => ({
        ...prev,
        [id]: updatedList,
      }));
    }
  };

  const isKCSAndSFDCRequired = taskControlFields.some(
    (field) => field.id === "kcsTagsRequired" || field.id === "sfdcTagsRequired"
  );
  const note = isKCSAndSFDCRequired
    ? "Note: Simply approve the task if KCS/SFDC tags are not required to be added."
    : null;

  return (
    <FlexItem flex={{ default: "flex_4" }}>
      <PageSection
        type="tabs"
        variant={PageSectionVariants.light}
        isWidthLimited
        style={{ minHeight: "400px", padding: 20 }}
      >
        <Form style={{ marginLeft: "10px" }}>
          {note && (
            <div
              style={{
                backgroundColor: "#f8f9fa",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              {note}
            </div>
          )}
          {taskControlFields?.length &&
            taskControlFields.map(
              ({
                id,
                label,
                isRequired,
                type,
                options,
                disableFormLabel,
                description,
                disabled,
              }) => {
                let inputField;

                switch (type) {
                  case "textarea":
                    inputField = (
                      <TextArea
                        id={id}
                        value={taskControlValues[id]}
                        onChange={(_event, value) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "text":
                    inputField = (
                      <TextInput
                        id={id}
                        isDisabled={disabled && !taskControlValues[disabled]}
                        value={taskControlValues[id]}
                        autoComplete="off"
                        onChange={(_event, value) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "conditionalMultiCheckbox":
                    inputField = options.map(
                      ({ label: optionLabel, id: optionId }) => {
                        return (
                          <Checkbox
                            id={optionId}
                            key={optionId}
                            isDisabled={!taskControlValues[disabled]}
                            onChange={(_event, checked) => {
                              setTaskControlValues((prevValues) => {
                                const currentArray = prevValues[id] || [];
                                let updatedArray;
                                if (checked) {
                                  updatedArray = [...currentArray, optionId];
                                } else {
                                  updatedArray = currentArray.filter(
                                    (item) => item !== optionId
                                  );
                                }
                                return {
                                  ...prevValues,
                                  [id]: updatedArray,
                                };
                              });
                            }}
                            isChecked={
                              taskControlValues[id]?.includes(optionId) || false
                            }
                            label={optionLabel}
                          />
                        );
                      }
                    );
                    break;

                  case "multiCheckbox":
                    inputField = (
                      <div key={id}>
                        {options.map(({ label: optionLabel, id: optionId }) => (
                          <Checkbox
                            key={optionId}
                            id={`${id}-${optionId}`}
                            onChange={(_event, checked) => {
                              setTaskControlValues((prevValues) => {
                                return {
                                  ...prevValues,
                                  [optionId]: checked,
                                };
                              });
                            }}
                            isChecked={taskControlValues[optionId] || false}
                            label={optionLabel}
                          />
                        ))}
                      </div>
                    );
                    break;

                  case "checkbox":
                    inputField = (
                      <Checkbox
                        id={id}
                        key={id}
                        description={description}
                        onChange={(_event, checked) => {
                          setTaskControlValues((prevValues) => {
                            const updatedValues = {
                              ...prevValues,
                              [id]: checked,
                            };
                            if (id === "kcsTagsRequired" && !checked) {
                              setKcsVariableList([]);
                              updatedValues["kcsVariables"] = [];
                            }
                            if (id === "sfdcTagsRequired" && !checked) {
                              setSfdcVariableList([]);
                              updatedValues["sfdcVariables"] = [];
                            }
                            return updatedValues;
                          });
                        }}
                        isChecked={taskControlValues[id] || false}
                        label={label}
                      />
                    );
                    break;

                  case "selectWithCreate":
                    inputField = (
                      <SelectTypeaheadCreatable
                        initialOptions={sbrTeams}
                        placeholder="Select an option"
                        onOptionSelect={(option) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: option,
                            isNewSBR: false,
                          })
                        }
                        onOptionCreate={(newOption) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: newOption.value,
                            isNewSBR: true,
                          })
                        }
                      />
                    );
                    break;

                  case "acknowledgement":
                    inputField = (
                      <>
                        <Title headingLevel="h4">{label}</Title>
                        <p>
                          {options
                            .filter((option) => taskDataInputs[option.id])
                            .map((option) => `- ${option.label}`)
                            .map((label, index) => (
                              <React.Fragment key={index}>
                                {label}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                      </>
                    );
                    break;

                  case "multiTags":
                    const isKcsField = id === "kcsVariables";
                    const isSfdcField = id === "sfdcVariables";
                    inputField = (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <TextInput
                            id={id}
                            isDisabled={
                              disabled && !taskControlValues[disabled]
                            }
                            value={isKcsField ? kcsVariable : sfdcVariable}
                            onChange={(_event, value) =>
                              isKcsField
                                ? setKcsVariable(value)
                                : setSfdcVariable(value)
                            }
                            onKeyPress={(event) => {
                              if (
                                event.key === "Enter" ||
                                event.key === "Return"
                              ) {
                                onClickAddMore(id);
                                event.preventDefault();
                              }
                            }}
                            autoComplete="off"
                          />
                          <Button
                            id={`${id}-button`}
                            variant="primary"
                            onClick={() => onClickAddMore(id)}
                            isDisabled={
                              (disabled && !taskControlValues[disabled]) ||
                              (isKcsField
                                ? kcsVariable.trim() === ""
                                : sfdcVariable.trim() === "")
                            }
                          >
                            Add
                          </Button>
                        </div>
                        {(isKcsField ? kcsVariableList : sfdcVariableList)
                          .length > 0 && (
                          <div
                            style={{
                              marginTop: "20px",
                              marginLeft: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Added tag(s):
                          </div>
                        )}
                        <LabelGroup
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                          isCompact={true}
                          numLabels={
                            isKcsField
                              ? kcsVariableList.length
                              : sfdcVariableList.length
                          }
                        >
                          {(isKcsField
                            ? kcsVariableList
                            : sfdcVariableList
                          ).map((listItem, index) => (
                            <Label
                              key={index}
                              color="blue"
                              onClose={() => onRemoveListItem(index, id)}
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              {listItem}
                            </Label>
                          ))}
                        </LabelGroup>
                      </>
                    );
                    break;
                }

                if (disableFormLabel) {
                  return <>{inputField}</>;
                } else
                  return (
                    <>
                      <FormGroup
                        key={id}
                        isRequired={isRequired}
                        label={label}
                        fieldId={id}
                      >
                        {inputField}
                      </FormGroup>
                    </>
                  );
              }
            )}
        </Form>
      </PageSection>
    </FlexItem>
  );
};

export default ExtendedTask;
