interface I_FIELDS {
  label: string;
  key: string;
  type: string;
  isRequired: boolean;
}

const fields: I_FIELDS[] = [
  {
    label: "Public Announcement Date",
    key: "publicAnnouncementDate",
    type: "date",
    isRequired: true,
  },
  {
    label: "Product Abbreviation",
    key: "productAbbreviation",
    type: "text",
    isRequired: true,
  },
  {
    label: "Product Contact List",
    key: "productContactList",
    type: "text",
    isRequired: false,
  },
  {
    label: "Prodsec Security Architect Name",
    key: "prodsecSecurityArchitectName",
    type: "text",
    isRequired: false,
  },
  {
    label: "Prodsec Security Architect Contact",
    key: "prodsecSecurityArchitectContact",
    type: "text",
    isRequired: false,
  },
  {
    label: "Prio List Google Group Link",
    key: "prioListGoogleGroupLink",
    type: "text",
    isRequired: false,
  },
  {
    label: "Prio List Email",
    key: "prioListEmail",
    type: "text",
    isRequired: false,
  },
  {
    label: "Engineering Support ContactList",
    key: "engineeringSupportContactList",
    type: "text",
    isRequired: false,
  },
  {
    label: "Engineering Jira Project Name",
    key: "engineeringJiraProjectName",
    type: "text",
    isRequired: false,
  },
  {
    label: "Engineering Slack Channel",
    key: "engineeringSlackChannel",
    type: "text",
    isRequired: false,
  },
  {
    label: "Service Definition Page Link",
    key: "serviceDefinitionPageLink",
    type: "text",
    isRequired: false,
  },
  {
    label: "Backplane Scripts Url",
    key: "backplaneScriptsUrl",
    type: "text",
    isRequired: false,
  },
  {
    label: "Workflow Details Document Link",
    key: "workflowDetailsDocumentLink",
    type: "text",
    isRequired: false,
  },
  {
    label: "Scripts Url",
    key: "scriptsUrl",
    type: "text",
    isRequired: false,
  },
  {
    label: "Instruct Url",
    key: "instructUrl",
    type: "text",
    isRequired: false,
  },
  {
    label: "Service Status Url",
    key: "serviceStatusUrl",
    type: "text",
    isRequired: false,
  },
  {
    label: "Helpful Url",
    key: "helpfulUrl",
    type: "text",
    isRequired: false,
  },
];

export default fields;
